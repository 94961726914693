import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppServiceService } from 'src/app/api/app-service.service';
import { TranslateService } from '@ngx-translate/core';
import { IonInput } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-order-type',
  templateUrl: './select-order-type.page.html',
  styleUrls: ['./select-order-type.page.scss'],
})
export class SelectOrderTypePage implements OnInit {
  @Input() just_select_address: boolean = false;
  @Input() just_select_order_type: boolean = false;
  @Input() currentUI: string = 'order-type';
  @Input() selected_branch: any = null;

  @Input() is_from_checkout_page : any = false;

  @ViewChild('googleaddress', { read: IonInput }) googleaddress: IonInput;

  public addressTemp = '';

  hasMultipleRestaurants: boolean = false;

  constructor(public modalController: ModalController, public appService: AppServiceService, public translate: TranslateService, public router: Router) {
    this.hasMultipleRestaurants = this.appService.appObject.settings && this.appService.appObject.settings.app_configuration && this.appService.appObject.settings.app_configuration.has_multiple_restaurants;
  }

  ngOnInit() {
    // get available ordering type of selected branch
    if(!this.just_select_order_type && this.selected_branch && this.selected_branch.openDetails){
      this.appService.findSelectedRestaurantOrderTypes(this.selected_branch.openDetails);
    }else if(!this.just_select_order_type && this.appService.appObject.settings?.main_restaurant?.openDetails){
      this.appService.findSelectedRestaurantOrderTypes(this.appService.appObject.settings.main_restaurant.openDetails);
    }

    if(this.is_from_checkout_page){
      this.addressTemp = this.appService.appObject.myAddress ? this.appService.appObject.myAddress.name : '';
      // initialize google places auto complete input once delivery selected
      setTimeout(() => {
        this.appService.appObject.isLoading = true;
      }, 10);
      setTimeout(() => {
        this.appService.initGoogle(this.googleaddress);
        this.appService.appObject.isLoading = false;
      }, 2000);
    }
  }

  async dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  /**
   * Trigger when user click choose address
   */
  async selectTypeAsDelivery() {
    this.appService.appObject.errors.address = '';
    // console.log('this.appService.appObject.myAddress : ', this.appService.appObject.myAddress);
    if (this.appService.appObject.myAddress.name != "" && this.appService.appObject.myAddress.lat != "" && this.appService.appObject.myAddress.lng != "") {
      this.appService.appObject.selectedDeliveryMethod = 'delivery';

      // if the user want to change the ordering type and select the address
      // then stop once user had it
      // if (this.just_select_order_type) {
      //   this.dismiss();
      //   return;
      // }

      this.appService.findNearByRestaurants((this.selected_branch ? 'branch-selected' : 'do-not-redirect'), false).then(async (response) => {
          // if the user is from checkout page, for just change the address
          // must calculate the order amount
        if (this.selected_branch || this.is_from_checkout_page || this.just_select_order_type) {
          // console.log('this.appService.appObject.selectedRestaurantDetails.id : ' , this.appService.appObject.selectedRestaurantDetails.id);
          // console.log('this.appService.appObject.restaurants : ', this.appService.appObject.restaurants);
          let selectedBranch = this.appService.appObject.restaurants.find((restaurant) => {
            return restaurant.id === (this.selected_branch ? this.selected_branch.id : this.appService.appObject.selectedRestaurantDetails.id)
          }); // this.selected_branch.id
          // console.log('selectedBranch : ' , selectedBranch)
          if (selectedBranch) {
            if(this.selected_branch) this.router.navigate(['restaurant', selectedBranch.slug]);
            // else if from checkout page or ordering main 2 page order type change popup
            // then calculate cart amount and close popup
            else if(this.is_from_checkout_page || this.just_select_order_type) {
              await this.appService.calculateCartAmounts();
            }
            this.dismiss();
          } else {
            // console.log('selected restaurant won\'t delivery to the selected address');
            if(this.is_from_checkout_page || this.just_select_order_type) this.appService.clearAddress('selected_restaurant_cannot_delivery_to_selected_address');

            this.appService.appObject.errors['address'] = ['selected_restaurant_cannot_delivery_to_selected_address'];
          }
        } else {
          if (this.appService.appObject.restaurants.length === 1 && this.appService.appObject.restaurants[0].slug) {
            this.dismiss();
            this.router.navigate(['restaurant', this.appService.appObject.restaurants[0].slug]);
          } else if(this.appService.appObject.restaurants && this.appService.appObject.restaurants.length > 0){
            this.dismiss();
            this.router.navigate(['restaurant-list']);

          }else{
            this.appService.appObject.errors['address'] = ['selected_restaurant_cannot_delivery_to_selected_address'];
          }
        }
      });
    } else {
      // if lat lng address not availbale then mark as invalid
      this.appService.appObject.errors['address'] = ['address_is_not_valid'];
    }
  }

  /**
   * Will trigger when user select an option from order type select popup
   * @param option selected order type option
   */
  async selectOrderType(option) {
    // if came from the order main page and not delivery (we must select the address if delivery)
    // just let the user to select ordering type
    if (option !== 'delivery' && (this.just_select_order_type || this.selected_branch)) {
      this.appService.appObject.selectedDeliveryMethod = option;

      // if user came from branches slider, then redirect into branch menu after selecting order type
      if (this.selected_branch) {
        this.router.navigate(['restaurant', this.selected_branch.slug]);
      }

      // calculate cart total when user change ordering type
      this.appService.calculateCartAmounts();

      this.dismiss();
      return;
    }

    if (option === 'delivery') {
      this.currentUI = 'delivery-address';
      if (this.appService.appObject.myAddress.name == "" && this.appService.appObject.myAddress.lat == "" && this.appService.appObject.myAddress.lng == "") {
        this.appService.getCurrentLocation();
      }

      // initialize google places auto complete input once delivery selected
      this.appService.appObject.isLoading = true;
      setTimeout(() => {
        this.appService.initGoogle(this.googleaddress);
        this.appService.appObject.isLoading = false;
      }, 2000);
    } else {
      this.appService.appObject.selectedDeliveryMethod = option;

      await this.appService.clearSelectedRestaurant();
      await this.appService.findNearByRestaurants('', true);

      this.appService.updateAppObject();

      // console.log('rest : ', this.appService.appObject.restaurants);

      // if (this.hasMultipleRestaurants) {
      //   this.router.navigate(['restaurant-list']);
      // } else if (!this.hasMultipleRestaurants) {
      //   if (this.appService.appObject.settings.main_restaurant.slug) this.router.navigate(['restaurant', this.appService.appObject.settings.main_restaurant.slug]);
      // }
      if (this.appService.appObject.restaurants && this.appService.appObject.restaurants.length > 1) {
        this.router.navigate(['restaurant-list']);
      } else if (this.appService.appObject.restaurants && this.appService.appObject.restaurants.length === 1) {
        if (this.appService.appObject.restaurants[0].slug) this.router.navigate(['restaurant', this.appService.appObject.restaurants[0].slug]);
      }
      this.dismiss();
    }
  }

}
