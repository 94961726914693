import { Component } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { AppServiceService } from './api/app-service.service';
import { CallNumber } from '@ionic-native/call-number/ngx';

//For One Signal Fix
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { RequestService } from 'src/app/core/request.service';
import { environment } from 'src/environments/environment';
import { ModalPopoverPage } from "./views/web-views/shared/modal-popover/modal-popover.page";

declare var config: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public viewType: any;
  public baseURL = environment.BASE_URL;
  public pages: any = {};

  public PRIVACY_POLICY_SLUG = 'pnp';
  public TERMS_AND_CONDITIONS_SLUG = 'tnc';

  public targetPage:string = '';
  
  constructor(
    public appService: AppServiceService,
    private platform: Platform,
    private callNumber: CallNumber,
    public splashScreen: SplashScreen,
    public statusBar: StatusBar,
    public oneSignal: OneSignal,
    public requestService: RequestService,
    public router: Router,
    private modalController: ModalController,
    private route: ActivatedRoute
  ) {
    route.queryParams.subscribe((parameters) => {
      // console.log('parameters : ' , parameters);
      if (parameters.target) {
        this.targetPage = parameters.target;
      }
    });
    //For One Signal Fix
    this.initializeApp();

  }

  async initializeApp() {
    this.platform.ready().then(async () => {

      this.splashScreen.show();
      await this.appService.appInit();
      
      // open slug if request contain 
      if (this.targetPage && this.appService.isWebVersion && this.appService.appObject.settings && this.appService.appObject.settings.pages) {
        let page = this.appService.appObject.settings.pages.find((page) => page.slug === this.targetPage) ;
        if(page){
          this.openPage(page.slug,page.name);
        }
      }


      let favIcon: any = document.getElementById('appFavIcon');
      if (favIcon && this.appService.appObject.favIcon) favIcon.href = this.appService.appObject.favIcon;

      // this.splashScreen.hide();
      this.statusBar.overlaysWebView(true);
      setTimeout(() => {
        this.splashScreen.hide();
      }, 3000);

      this.requestService.post('onesignal/key?device_type=' + config.plf, []).then(data => {
        if (data.status) {

          //set one signal id for web to init
          config.osid = data.onesignal_key;
          this.appService.appObject.oneSignalConfig.key = data.onesignal_key;
          this.appService.appObject.oneSignalConfig.fireid = data.firebase_id;

          //For One Signal Fix
          if ((this.platform.is('android') || this.platform.is('ios')) && !this.appService.isMobileWeb()) {
            this.oneSignal.startInit(this.appService.appObject.oneSignalConfig.key, this.appService.appObject.oneSignalConfig.fireid);
            this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
            this.oneSignal.handleNotificationReceived().subscribe(data => {
              // do something when notification is received 
            });
            this.oneSignal.handleNotificationOpened().subscribe(data => {
              // do something when a notification is opened
            });
            this.oneSignal.endInit();

            this.oneSignal.getIds().then(identity => {
              //this.appService.showAlert('success','success',JSON.stringify(identity));
              this.appService.appObject.deviceUUID = identity.userId;
              this.doUpdate();
            }, err => {
              //this.appService.showAlert('error','error',err);
            });

          }

        }
      });

    });
  }

  call() {
    // console.log('this.appService.appObject.selectedRestaurantDetails.order_mobile_number_1 : ', this.appService.appObject.selectedRestaurantDetails.order_mobile_number_1);
    // console.log('this.appService.appObject.selectedRestaurantDetails.order_mobile_number_1 : ', this.appService.appObject.settings.main_restaurant.order_mobile_number_1);
    let mobileNumber = '';
    if (this.appService.appObject) {
      if (this.appService.appObject.selectedRestaurantDetails && this.appService.appObject.selectedRestaurantDetails.order_mobile_number_1) {
        mobileNumber = this.appService.appObject.selectedRestaurantDetails.order_mobile_number_1;
      } else if (this.appService.appObject.settings && this.appService.appObject.settings.main_restaurant && this.appService.appObject.settings.main_restaurant.order_mobile_number_1) {
        mobileNumber = this.appService.appObject.settings.main_restaurant.order_mobile_number_1;
      }
    }

    // removes all white spaces from mobile number string  
    if (mobileNumber) {
      mobileNumber = mobileNumber.replace(/\s/g, '');
    }

    if (mobileNumber) {
      this.callNumber.callNumber(mobileNumber, true)
        .then(res => console.log('Launched dialer!', res))
        .catch(err => console.log('Error launching dialer', err));
    } else {
      console.log('mobile number not found');
    }
  }

  async doUpdate() {
    console.log('DO_UPDATEEEEE');
    await this.appService.deviceRegister(true);
    await this.appService.updateAppObject();
  }

  cookiesAccepted() {
    this.appService.appObject.isCookiesAccepted = true;
    this.appService.updateAppObject();
  }

  cookiesBannerDismissed() {
    if (this.appService?.appObject?.settings?.pages) {
      var page = this.appService.appObject.settings.pages.filter(function (el) {
        return el.slug == "pnp";
      });
      if (typeof page[0] !== "undefined") {
        page = page[0];
        this.openPage(page.slug, page.name || 'Privacy Policy');
      } else {
        this.cookiesBannerDismissedNotPage();
      }
    } else {
      this.cookiesBannerDismissedNotPage();
    }
  }

  cookiesBannerDismissedNotPage() {
    let cookiesBanner = document.getElementById('cookies-banner');
    if (cookiesBanner) {
      cookiesBanner.remove();
    }
  }

  displayCookieBanner() {
    if ((this.appService.isWebVersion || ((this.platform.is('android') || this.platform.is('ios')) && this.appService.isMobileWeb())) && (this.appService.appObject && !this.appService.appObject.isCookiesAccepted)) {
      return true;
    }
    return false;
  }

  displayLinks(slug) {
    if (this.appService.appObject && this.appService.appObject.settings && this.appService.appObject.settings.pages) {
      if (this.appService.appObject.settings.pages.length) {
        // console.log('this.appService.appObject.currentLang : ', slug + '-' + this.appService.appObject.currentLang);
        let page = this.appService.appObject.settings.pages.find((page) => page.slug === (slug + '-' + this.appService.appObject.currentLang) && page.status === 'true')
        if (page) {
          this.pages[slug] = page;
          return true;
        }
        else return false;
      }
    }
  }

  async openSlug(slug, title) {
    if (this.appService.isWebVersion) {
      const modal = await this.modalController.create({
        component: ModalPopoverPage,
        cssClass: 'order-popup',
        componentProps: {
          'type': 'pages',
          'title': title,
          'slug': (slug + '-' + this.appService.appObject.currentLang)
        }
      });

      await modal.present();
    } else {
      this.router.navigate(['pages/' + (slug + '-' + this.appService.appObject.currentLang)]);
    }
  }

  async openPage(slug, title) {
    const modal = await this.modalController.create({
      component: ModalPopoverPage,
      cssClass: 'order-popup',
      componentProps: {
        'type': 'pages',
        'title': title,
        'slug': slug
      }
    });

    await modal.present();
  }

}
