import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppServiceService } from 'src/app/api/app-service.service';
import { ToppingsPage } from '../toppings/toppings.page';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.page.html',
  styleUrls: ['./cart.page.scss'],
})
export class CartPage implements OnInit {

  public couponAdded = false;
  public isheighok = true;
  coupon: any = '';
  public isCouponStatusChecking = false;

  constructor(public modalController: ModalController, public appService: AppServiceService) {
    if (this.appService.appObject.selectedCoupon.length > 0) {
      this.coupon = this.appService.appObject.selectedCoupon[0]['coupon_code'];
      this.couponAdded = true;
    }

  }

  ngOnInit() {
    this.appService.cartIsOpen = true;
    console.log(this.appService.appObject.orderObject);

    const newThis = this;
    setInterval(() => {
      newThis.isheight();
    }, 500);
  }

  public isheight(): void {

    if (document.querySelectorAll<HTMLElement>('.popup-container').length) {


      const element = document.querySelectorAll<HTMLElement>('.popup-container')[0];
      const element2 = document.querySelectorAll<HTMLElement>('.popup-container-inner')[0];

      if (element.offsetHeight < element2.offsetHeight) {
        this.isheighok = false;
      } else {
        this.isheighok = true;
      }

    }
  }

  public dismiss() {
    this.appService.cartIsOpen = false;
    this.appService.hideCartButton = false;
    //this.appService.getRestaurantBySlug(this.appService.appObject.selectedRestaurant);
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    if (!this.appService.appObject.orderObject.cart_summery.min_reached && this.appService.appObject.orderObject.cart_summery.min_amount > 0) {
      this.appService.routeToSelectedRestaurant();
    }
    this.modalController.dismiss({
      'dismissed': true,
      'array': 'kk'
    });
  }

  async removeCoupon() {
    this.couponAdded = false;
    this.coupon = '';
    this.appService.appObject.selectedCoupon = [];
    this.appService.calculateCartAmounts();
  }

  async addCoupon() {

    if(this.coupon === ""){
      return false;
    }

    this.isCouponStatusChecking = true;
    await this.appService.addCoupon(this.coupon).then(data => {
      if (data.status) {

        this.couponAdded = true;
        //set coupon details
        this.appService.appObject.selectedCoupon = [];
        data.data['coupon_code'] = this.coupon;
        this.appService.appObject.selectedCoupon.push(data.data);
        this.appService.calculateCartAmounts();
      } else {
        this.couponAdded = false;
        this.coupon = '';
        this.appService.showAlert('error','',data.msg);
      }
      this.isCouponStatusChecking = false;
    }, error => {
      this.isCouponStatusChecking = false;
      this.appService.appObject.isLoading = false;
    });

  }

  async incDish(dish) {
    dish.count++;
    this.appService.calculateCartAmounts();
  }


  async decDish(dish) {
    if (dish.count > 1) {
      dish.count--;
      this.appService.calculateCartAmounts();
    }
  }

  async remove(index) {
    this.appService.appObject.orderObject.cart.splice(index, 1);
    this.appService.appObject.orderObjectDup.cart.splice(index, 1);
    this.appService.calculateCartAmounts();
    if (this.appService.appObject.orderObject.cart.length == 0) {
      this.dismiss();
    }
  }

  async edit(index, dish) {

    this.appService.selectedDish.size_id = dish.size_info.size_id;
    this.appService.editing_cart_item = index;

    this.appService.selectedDish.selectedTopings = dish.size_info.topping_info;
    this.appService.selectedDish.fullDishInfo = JSON.parse(JSON.stringify(this.appService.appObject.orderObjectDup.cart[index]));

    this.modalController.dismiss({
      'dismissed': true,
      'array': 'kk'
    });
    this.appService.hideCartButton = true;
    const modal = await this.modalController.create({
      component: ToppingsPage,
      cssClass: 'custom-popup'
    });

    await modal.present();

  }

  onclickAny() {
    console.log('hi');
  }

}
